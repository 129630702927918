import GoogleButton from 'react-google-button'
import {auth, GoogleAuthProvider, signInWithPopup} from '../../firebase/firebase.ts'
import {useEffect, useState} from 'react'
import {RedirectingToCheckout} from '../../components/RedirectingToCheckout.tsx'
import mixpanel from 'mixpanel-browser'
import {Navigate} from 'react-router-dom'
import {useAuth} from '../../contexts/auth.tsx'
import {useSubscriptions} from '../../contexts/subscriptions.tsx'
import {useTranslation} from 'react-i18next'

function LoginButton(props: { onClick: () => Promise<void> }) {
    const {t} = useTranslation()

    useEffect(() => {
        mixpanel.track_links('#email-link', 'Link Clicked', {linkId: 'email-link'})
        mixpanel.track_links('#email-link', 'Link Clicked', {linkId: 'phone-link'})
    }, [])

    return <div className="flex h-full justify-center items-center bg-gray-100">
        <div className="bg-white p-8 rounded-lg shadow-lg max-w-md w-full flex flex-col items-center">
            <h1 className="text-3xl font-bold text-center text-primary mb-4">{t('welcome.to.botwhirl', 'Welcome to BotWhirl')}</h1>
            <p className="text-center text-gray-600 mb-6">{t('sign.in.with.google', 'Please sign in with your google account to start creating your chatbots')}</p>

            <iframe id="lottie-iframe" className="h-[15rem] mb-6 hidden"
                src="https://lottie.host/embed/05a3f4dd-3821-449e-9a15-18c4a6764fc0/ql3slmjXf7.json"
                onLoad={() => document.getElementById('lottie-iframe')!.classList.remove('hidden')}
            />
            <GoogleButton onClick={props.onClick}/>
            <span className="text-gray-600 text-xs mt-2">{t('need.help', 'If you need help:')}&nbsp;
                <a id="email-link" href="mailto:botwhirl@gmail.com"
                    className="text-violet-800 underline">botwhirl@gmail.com</a>
                &nbsp; - &nbsp;
                <a id='phone-link' href="https://wa.me/+34677166464"
                    className="text-violet-800 underline">+34677166464</a>
            </span>
        </div>
    </div>
}

export const LoginPage = () => {
    const [isRedirectingToCheckout, setIsRedirectingToCheckout] = useState(false)
    const {user} = useAuth()
    const {isSubscribed} = useSubscriptions()
    // const selectedPrice = useQuery('price') ?? DEFAULT_PRICE_99

    const handleLogin = async () => {
        try {
            mixpanel.track('Button Clicked', {button_id: 'google-login'}, {send_immediately: true})
            await signInWithPopup(auth, new GoogleAuthProvider())
            // if(!await hasActiveSubscription(user.uid)) {
            //     setIsRedirectingToCheckout(true)
            //     console.log('User does not have an active subscription')
            //     mixpanel.track('User redirected to checkout', {price: selectedPrice}, {send_immediately: true})
            //     await goCheckout(user.uid, selectedPrice)
            // }
        } catch (error) {
            console.error('Error logging in:', error)
            setIsRedirectingToCheckout(false)
        }
    }

    if (user && !user.isAnonymous && isSubscribed) {
        return <Navigate to="/create"/>
    }

    return (
        <>
            {
                isRedirectingToCheckout ?
                    <RedirectingToCheckout/>
                    :
                    <LoginButton onClick={handleLogin}/>
            }
        </>
    )
}
