import {initializeApp} from "firebase/app"
import {connectFunctionsEmulator, getFunctions} from "firebase/functions"
import {
    connectAuthEmulator,
    getAuth,
    GoogleAuthProvider,
    signInWithPopup,
    signOut,
    onAuthStateChanged as firebaseOnAuthStateChanged,
    Unsubscribe,
} from "firebase/auth"
import {getAnalytics} from "firebase/analytics"
import {connectFirestoreEmulator, getFirestore} from 'firebase/firestore'
import {connectStorageEmulator, getStorage} from 'firebase/storage'
import {isLocalEnvironment} from '../environment.ts'


// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyB_rFlfocOvFQcUVNY__CEHUeRmP5ypiBI",
    authDomain: "app.botwhirl.com",
    projectId: "botwhirl-3098c",
    storageBucket: "botwhirl-3098c.appspot.com",
    messagingSenderId: "517090873771",
    appId: "1:517090873771:web:b8f677446a0a29cb9942ee",
    measurementId: "G-X6GLV5RMHM",
}

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig)
const auth = getAuth(firebaseApp)
getAnalytics(firebaseApp)
const functions = getFunctions(firebaseApp)
const firestore = getFirestore(firebaseApp)
const storage = getStorage(firebaseApp)

// Connect to the emulator if in development
if (isLocalEnvironment) {
    connectAuthEmulator(auth, `http://${window.location.hostname}:5004`)
    connectFunctionsEmulator(functions, window.location.hostname, 5001)
    connectFirestoreEmulator(firestore, window.location.hostname, 5003)
    connectStorageEmulator(storage, window.location.hostname, 5005)
}

type OnAuthStateChangedParams = Parameters<typeof firebaseOnAuthStateChanged>

const onAuthStateChanged =
    (callback: OnAuthStateChangedParams[1]): Unsubscribe =>
        firebaseOnAuthStateChanged(auth, callback)
export {
    auth,
    GoogleAuthProvider,
    signInWithPopup,
    signOut,
    functions,
    firebaseApp,
    onAuthStateChanged,
    firestore,
    storage,
}
